import React, { useState } from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { graphql, Link, navigate } from "gatsby";
import Layout from "../components/layout";
import {
  BuildingIcon,
  CircleTickIcon,
  PhoneChatIcon,
} from "../components/Icons";
import Seo from "../components/seo";
import FaqAccordion from "../components/FaqAccordion";
import {
  Section,
  SectionPageTitle,
  Container,
  SectionTitle,
  SectionDescription,
} from "../components/Section";
import DefaultButton from "../components/Button/DefaultButton";
import PrimaryButton from "../components/Button/PrimaryButton";
import {
  TopHeaderGrid,
  TopLeftHeader,
  TopRightHeader,
  StateHero,
  StateBanner,
  StateMap,
  PopularSize,
  PopularAction,
  SizeTitle,
  ListSize,
  ListItemSize,
  ListCity,
  ListItemCity,
  ListCityAction,
  FaqGrid,
  FaqLeftGrid,
  FaqRightGrid,
  BuildGrid,
  BuildLeftGrid,
  BuildRightGrid,
  HeroBackground,
  Service,
  ServiceLeftItem,
  ServiceRightItem,
  StyledWindowedSelect,
} from "../components/stateTemplate";
import ProductSection from "../sections/Home/ProductSection";

const StateTemplate = ({ location, data }) => {
  const [limit, setlimit] = useState(30);
  const {
    contentfulStatePages: stateData,
    allContentfulStatePages: { edges: allStates },
  } = data;

  const popularLocations = allStates.reduce((pLocations, state) => {
    state.node.popularCities &&
      state.node.popularCities.forEach((popularCity) => {
        pLocations.push({
          label: popularCity,
          value: state.node.stateName.split(" ").join("-").toLowerCase(),
        });
      });

    return [...pLocations];
  }, []);

  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: stateData.stateName,
        item: `https://www.engineeredmetalbuildings.com/${stateData.stateName}/`,
      },
    ],
  };

  const schema = [breadcrumbSchema];
  return (
    <Layout location={location}>
      <Seo
        title={stateData.metaTitle}
        description={stateData.metaDescription}
        schemaMarkup={schema}
      />
      <Section
        ept="160px"
        epb="60px"
        xpt="140px"
        xpb="40px"
        pt="100px"
        pb="15px"
        bgColor="#fff"
        className="section-state"
      >
        <Container>
          <SectionPageTitle textAlign="center" color="#00236A" mb="0">
            {`Metal Buildings ${stateData.stateName}`}
          </SectionPageTitle>
          <TopHeaderGrid>
            <TopLeftHeader>
              <PopularSize>
                <SizeTitle>{`Popular Sizes in ${stateData.stateName}`}</SizeTitle>
                <ListSize>
                  {stateData.metalBuildings.map((building, index) => (
                    <ListItemSize className="list-item">
                      <Link to={building.url}>
                        <span className="icon" key={index}>
                          <BuildingIcon />
                        </span>
                        <span className="text">{building.title}</span>
                      </Link>
                    </ListItemSize>
                  ))}
                </ListSize>
              </PopularSize>
              <PopularAction>
                <a href="tel:4792235995" aria-label="phone number">
                  <PrimaryButton
                    icon={<PhoneChatIcon />}
                    text="(479) 223-5995"
                    size="xl"
                  />
                </a>
              </PopularAction>
            </TopLeftHeader>
            <TopRightHeader>
              <StateHero>
                <StateBanner>
                  <GatsbyImage
                    image={stateData.metalBuildingsImage.gatsbyImageData}
                    alt="Metal Building Image"
                  />
                </StateBanner>
                <StateMap>
                  <GatsbyImage
                    image={stateData.stateLocationPin.gatsbyImageData}
                    alt={stateData.stateName}
                  />
                </StateMap>
              </StateHero>
            </TopRightHeader>
          </TopHeaderGrid>
        </Container>
      </Section>

      <ProductSection data={data.allContentfulProduct.edges} />

      
      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="20px"
        bgColor="#fff"
        className="section-certify"
        zIndex="0"
      >
        <BuildGrid>
          <BuildLeftGrid>
            <HeroBackground>
              <GatsbyImage
                image={stateData.certifiedSectionImage.gatsbyImageData}
                alt={`Certified ${stateData.stateName} Metal Buildings`}
              />
            </HeroBackground>
          </BuildLeftGrid>
          <BuildRightGrid>
            <SectionTitle>{stateData.certifiedSectionTitle}</SectionTitle>
            <SectionDescription
              mb="0"
              dangerouslySetInnerHTML={{
                __html:
                  stateData.certifiedSectionDescription.childMarkdownRemark
                    .html,
              }}
            />
          </BuildRightGrid>
        </BuildGrid>
      </Section>
      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="20px"
        pb="15px"
        bgColor="#fff"
        className="section-location"
      >
        <Container>
          <SectionTitle textAlign="center">
            {`Frequently Asked Questions in ${stateData.stateName}`}
          </SectionTitle>
          <FaqGrid>
            <FaqLeftGrid>
              <FaqAccordion faqs={stateData.frequentlyAskedQuestions} />
            </FaqLeftGrid>
            <FaqRightGrid>
              <StaticImage src="../images/faq-charator.png" alt="Faq" />
            </FaqRightGrid>
          </FaqGrid>
        </Container>
      </Section>
      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="15px"
        pb="30px"
        bgColor="#eef0fd"
        className="section-city"
      >
        <Container>
          <SectionTitle textAlign="center">
            {`Popular Delivery Locations in ${stateData.stateName}`}
          </SectionTitle>
          <SectionDescription textAlign="center">
            <p>
              {`Our installers deliver metal buildings near you all over Western, Central, and Eastern ${stateData.stateName}, including:`}
            </p>
          </SectionDescription>
          <ListCity>
            {stateData.popularDeliveryLocations
              .slice(0, limit)
              .map((locationName, index) => (
                <ListItemCity className="list-item">
                  <span className="icon" key={index}>
                    <CircleTickIcon />
                  </span>
                  <span className="text">{locationName.content}</span>
                </ListItemCity>
              ))}
          </ListCity>
          {limit < stateData.popularDeliveryLocations.length && (
            <ListCityAction>
              <DefaultButton
                text="Load More"
                size="xl"
                onClick={() => setlimit(limit + 30)}
              />
            </ListCityAction>
          )}
        </Container>
      </Section>
      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="15px"
        pb="30px"
        bgColor="#fff"
        className="section-service"
      >
        <Container>
          <Service>
            <ServiceLeftItem>
              <SectionTitle>Popular Service Locations</SectionTitle>
              <SectionDescription>
                <p>
                  We proudly offer delivery and installation of metal buildings
                  throughout various regions in the U.S. Some of the top areas
                  we serve include:
                </p>
              </SectionDescription>
            </ServiceLeftItem>
            <ServiceRightItem>
              {typeof window !== `undefined` && (
                <StyledWindowedSelect
                  options={popularLocations}
                  onChange={(e) => {
                    if (e.value) navigate(`/${e.value}`);
                  }}
                  placeholder="Browse Popular Locations..."
                />
              )}
            </ServiceRightItem>
          </Service>
        </Container>
      </Section>
    </Layout>
  );
};

export default StateTemplate;

export const pageQuery = graphql`
  query StateTemplateQuery($id: String!) {
    contentfulStatePages(id: { eq: $id }) {
      metaTitle
      metaDescription
      stateName
      metalBuildings {
        title
        url
      }
      metalBuildingsImage {
        gatsbyImageData(quality: 50, placeholder: BLURRED)
        fluid {
          srcWebp
        }
      }
      stateLocationPin {
        gatsbyImageData(quality: 50, placeholder: BLURRED)
        fluid {
          srcWebp
        }
      }
      popularDeliveryLocations {
        content
      }
      frequentlyAskedQuestions {
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
      certifiedSectionTitle
      certifiedSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      certifiedSectionImage {
        gatsbyImageData(quality: 50, placeholder: BLURRED)
        fluid {
          srcWebp
        }
      }
    }
    allContentfulStatePages(filter: { id: { ne: $id } }) {
      edges {
        node {
          stateName
          popularCities
        }
      }
    }
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }, limit: 6) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          specifications {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
